@import "./Base.main.scss";

#footer{
    /* clear:both; */
    position: absolute;
    bottom: 0;
    width: 100%;
    background: $lightpink;
    border-top: 3px solid $pink;
    /* z-index: 1; */
    /* padding: 20px; */
}

#footer ul{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left:0;
    /* padding: 20px; */
}

#footer ul li{
    list-style-type: none;
    padding:0px 30px;
}


.socials{
    color: $pink;
    /* color:$lightpink; */
    font-size: 28px;
}

@media only screen and (max-width: 550px) {
    #footer ul li{
        padding:0px 20px;
    }
  }