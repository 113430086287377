@import "./Base.main.scss";

#about {
  font-family: "Cairo", sans-serif;
  margin: auto;

  h2 {
    text-align: center;
    font-size: 48px;
    font-family: "Oswald", sans-serif;
    color: $pink;
    text-transform: uppercase;
    margin-top: 0;
  }

  .aboutFlex {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    align-items: center;

    .aboutContent {
      width: 60%;
      box-sizing: border-box;
      padding: 20px;
      color: $pink;
      font-weight: 600;
      /* justify-content: center; */
      p{
        margin: 0;
      }
      a {
        color: $pink;
        text-decoration: underline;
      }
    }

    .aboutPicture {
      width: 40%;
      box-sizing: border-box;
      padding: 10px;
      /* border-right: 2px solid black; */
      color: $pink;
      box-shadow: 0 4px 8px 0 $pink;
      transition: all 0.3s;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:hover {
        box-shadow: 0 8px 16px 0 $pink;
      }

      img {
        width: 60%;
      }
      .aboutInfo {
        display: flex;
        margin-top: 10px;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        .aboutInfoSpan {
          display: flex;
          justify-content: flex-start;
          span {
            width: 2em;
          }
        }
        a {
          text-decoration: none;
          color: $pink;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  #about {
    .aboutFlex {
      flex-direction: column;

      .aboutPicture {
        width: 100%;
      }

      .aboutContent {
        width: 100%;
      }
    }
    .aboutPicture2 img {
      max-width: 100%;
      height: auto;
      width: 300px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
}
