@import "./Base.main.scss";

#tabsList {
  display: flex;
  list-style-type: none;
  border: none;
  padding-left: 0;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
} 

.active {
  background-color: $pink;
  border: none;
  color: $lightpink;
}

.inactive {
  border: none;
  background-color: white;
  transition: background-color 0.4s;
}

.inactive:hover {
  background-color: $pink;
}

.tab-item {
  display: inline-block;
  padding: 15px;
}

.tab-button {
  font-family: 'Cairo', sans-serif;
  font-size: 16px;
  padding: 15px;
  border-radius: 1em;
  font-weight: 600;
  cursor: pointer;
}

.active.tab-button{
  color: $lightpink;
}

.inactive.tab-button {
  color: $pink;
}

.inactive.tab-button:hover {
  color: $lightpink;
}
.grid-container {
  align-items: center;
  justify-content: center;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 0;
  margin-left: 0;
}
.grid-item {
  background-color: white;
  border-radius: 1em;
  box-sizing: border-box;
  /* border: 2px solid $pink; */
  margin: 15px;
  padding: 10px 20px;
  font-size: 18px;
  /* text-align: center; */
  /* max-width: 33%; */
  /* min-width: 20%; */
  width: 310px;
  font-weight: 400;
  color: $pink;
  font-family: 'Cairo', sans-serif;
  /* box-shadow: 0  5px 10px rgba(154,160,185,0.05), 0 15px 40px rgba(166,173,201,0.2); */
  box-shadow: 0 4px 8px 0 $pink;
  transition: all 0.3s;
}
