@import "./Base.main.scss";

#education {
  margin: 0px 20px;
  font-family: 'Cairo', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  box-sizing: border-box;
}

#education hr {
  background-color: $pink;
  padding: 1px;
  border: none;
}

#eduImage {
  box-sizing: border-box;
  text-align: center;
}

#eduImage img {
  max-width: 400px;
  max-height: 400px;
}

#experience {
  margin: 0px 20px;
  padding-bottom: 20px;
  font-family: 'Cairo', sans-serif;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#experience hr {
  background-color: $pink;
  padding: 1px;
  border: none;
}

.highlight {
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
  font-size: 24px;
  text-decoration: underline;
  margin-bottom: 0;
}

.tabContent {
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
}

#skillset {
  box-sizing: border-box;
}

#courses {
  width: 100%;
}


@keyframes fade-in-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#courses {
  box-sizing: border-box;
  padding: 15px;
}

#courseList {
  align-items: center;
  justify-content: center;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 0;
  margin-left: 0;
}

#courseList li {
  background-color: white;
  border-radius: 1em;
  box-sizing: border-box;
  margin: 15px;
  padding: 10px 20px;
  font-size: 18px;
  width: 290px;
  font-weight: 600;
  color: $pink;
  font-family: 'Cairo', sans-serif;
  box-shadow: 0 4px 8px 0 $pink;
  transition: all 0.3s;
  height: 88px;
  display: flex;
  align-items: center;
}

#courseList li:hover{
  box-shadow: 0 8px 16px 0 $pink;
}

#coursesHeader {
  font-family: 'Oswald', sans-serif;
  margin-top: 0;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: $pink;
  text-transform: uppercase;
}

.grid-container {
  align-items: center;
  justify-content: center;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 0;
  margin-left: 0;
}
.grid-item {
  background-color: white;
  border-radius: 1em;
  box-sizing: border-box;
  margin: 15px;
  padding: 10px 15px;
  font-size: 18px;
  width: 320px;
  font-weight: 400;
  color: $pink;
  font-family: 'Cairo', sans-serif;
  box-shadow: 0 4px 8px 0 $pink;
  transition: all 0.3s;
}

.grid-item:hover {
  box-shadow: 0 8px 16px 0 $pink;
}

.grid-item hr {
  border: 1px dashed $pink;
}

#eduHeader {
  font-family: 'Oswald', sans-serif;
  margin-top: 0;
  text-align: center;
  font-size: 48px;
  font-weight: 500;
}

.grid-item span {
  font-weight: 900;
}

.resumeCard{
  background-color: white;
  border-radius: 1em;
  box-sizing: border-box;
  padding: 2px 12px;
  margin: 15px;
  box-shadow: 0 4px 8px 0 $pink;
  color: $pink
}

.resumeCard:hover{
  box-shadow: 0 8px 16px 0 $pink;
}

.experience-flex{
  display: flex;
  flex-direction: row;
}

.experience-left{
  width: 50%;
}

.experience-right{
  width: 50%;
}

.experience-flex p{
  margin-top: 2px;
}

@media only screen and (max-width: 550px) {
  #eduImage img{
    max-width: 330px;
  }
}

@media only screen and (max-width: 400px) {
  .highlight{
    font-size: 20px;
    max-width: 150px;
  }
}

@media only screen and (max-width: 1024px) {
  .bar-graph-horizontal{
    padding: 25px;
  }
}