@import "./styles/Base.main.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: $pink;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main{
  margin-top: 75px;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
  padding-bottom: 65px;
}

#page-container{
  position: relative;
  min-height: 90vh;
}

#footerTest{
  position: absolute;
  width: 100%;
  bottom: 0;
  /* min-height: 80vh; */
  /* z-index: -1; */
}

.Typist{
  /* margin-top: 200px; */
  /* font-family: 'Shrikhand', cursive; */
  font-family: 'Oswald', sans-serif;
  /* position: absolute; Position the background text */
  /* top: 25px; At the bottom. Use top:0 to append it to the top */
  /* background: rgb(0, 0, 0); Fallback color */
  /* background: rgba(0, 0, 0, 0.3);  Black background with 0.5 opacity */
  color: black; /* Grey text */
  /* Full width */
  /* padding: 20px;  Some padding */
  font-size: 36px;
  text-shadow: $pink 0px 0px 6px;
  /* width: 100%; */
  /* z-index: -1; */
}

/* .carousel .carousel-slider {
  height: 95vh;
} */

html{
  /* background: linear-gradient(#69C2FF,white,#69C2FF); */
  background: $lightpink;
  height: 100%;
  z-index: -2;
}

.home{
  z-index:1;
}

